import React from "react";

export const GalleryCard = ({ item }: any) => {
  const defaultImage =
    "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTNNLEL-qmmLeFR1nxJuepFOgPYfnwHR56vcw&s";

  return (
    <div
      key={item._id}
      className="border rounded-lg shadow-sm p-3 bg-white transform transition-all hover:scale-105"
    >
      <img
        src={item.files[0]?.awsURL || defaultImage}
        alt={item.category}
        className="h-40 w-full object-contain rounded-md"
        onError={(e) => ((e.target as HTMLImageElement).src = defaultImage)}
      />
      <div className="mt-2">
        <h3 className="text-lg font-semibold text-gray-800">
          {item.shop.shopName}
        </h3>
        <p className="text-sm text-gray-500">📞 {item.shop.shopMobileNo}</p>
        <p className="text-sm text-gray-500">
          📍 {item.shop.address}, {item?.shop?.city?.toUpperCase()},{" "}
          {item?.shop?.state?.toUpperCase()}
        </p>
        <p className="text-sm text-gray-600 mt-1">Category: {item.category}</p>
        <p className="text-sm text-gray-600">Notes: {item.notes}</p>
        <p className="text-sm text-gray-400 text-xs mt-1">
          📅 Created: {new Date(item.createdAt).toLocaleDateString()}
        </p>
      </div>
    </div>
  );
};
