import { configureStore } from "@reduxjs/toolkit";
import {
  getAllGalleryReducer,
  getGalleryCategoryReducer,
  getGalleryListByShopIdReducer,
} from "reducers/galleryReducer";
import {
  getAllProductListReducer,
  getProductListByShopIdReducer,
} from "reducers/productReducers";
import { getShopListReducer } from "reducers/shopReducers";
import { thunk } from "redux-thunk";

const initialState = {
  userSignin: {
    userInfo: localStorage.getItem("user")
      ? JSON.parse(localStorage.getItem("user"))
      : null,
  },
};

const store = configureStore({
  initialState,
  reducer: {
    // shop
    shopList: getShopListReducer,
    // product
    productListByShopId: getProductListByShopIdReducer,
    productList: getAllProductListReducer,

    // gallery

    getAllGalleryReducer,
    getGalleryListByShopIdReducer,
    getGalleryCategoryReducer,
  },
  middleware: () =>
    process.env.NODE_ENV !== "production"
      ? [require("redux-immutable-state-invariant").default(), thunk]
      : [thunk],
  // devTools: process.env.NODE_ENV !== 'production'
});

store.subscribe(() => {
  const state = store.getState();
  if (state?.auth?.isLoggedIn) {
    localStorage.setItem(
      "user",
      JSON.stringify({
        userInfo: state.auth.userInfo,
        loginTime: state.auth.loginTime,
      })
    );
    // localStorage.setItem(
    //   "userInfo",
    //   JSON.stringify({ userInfo: state.auth.userInfo })
    // );
  } else {
    localStorage.removeItem("user");
    localStorage.removeItem("userInfo");
  }
});

export default store;
