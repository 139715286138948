import Axios from "axios";
import {
  SHOP_LIST_FAIL,
  SHOP_LIST_REQUEST,
  SHOP_LIST_SUCCESS,
} from "../constants/shopConstants";
import { getAuthorization } from "../utils/axiosUtils";

const BASE_URL = `${process.env.REACT_APP_BLINDS_SERVER}/api/v1/shops`;

export const getShopList = () => async (dispatch, getState) => {
  dispatch({
    type: SHOP_LIST_REQUEST,
    payload: {},
  });

  try {
    const { data } = await Axios.get(`${BASE_URL}/all`);
    dispatch({
      type: SHOP_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({
      type: SHOP_LIST_FAIL,
      payload: message,
    });
  }
};
