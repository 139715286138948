import { LoadingScreen, ProductCard } from "../../components";
import { allProductListAction } from "../../actions/productAction";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

export const HomePage = () => {
  const dispatch = useDispatch<any>(); // TypeScript-specific
  const [searchText, setSearchText] = useState<string>("");

  console.log("Home page");
  const productList = useSelector((state: any) => state.productList);
  const { error, loading, data: products } = productList;

  useEffect(() => {
    dispatch(allProductListAction());
  }, [dispatch]);

  return (
    <div className="p-4">
      {/* Filter Section */}
      <div className="mb-4">
        <div className="text-lg font-semibold">Filter Section</div>
        {/* Add your filter input or buttons here */}
      </div>

      {/* Loading Screen */}
      {loading ? (
        <LoadingScreen />
      ) : (
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 gap-4">
          {/* Filtering the products based on searchText */}
          {products
            ?.filter((product: any) =>
              product.description
                .toLowerCase()
                ?.includes(searchText.toLowerCase())
            )
            .map((product: any) => (
              <ProductCard product={product} key={product?._id} />
            ))}
        </div>
      )}
    </div>
  );
};
