import { getAuthorization } from "../utils/axiosUtils";
import {
  GET_ALL_PRODUCT_BY_SHOP_ID_FAIL,
  GET_ALL_PRODUCT_BY_SHOP_ID_REQUEST,
  GET_ALL_PRODUCT_BY_SHOP_ID_SUCCESS,
  GET_ALL_PRODUCT_FAIL,
  GET_ALL_PRODUCT_REQUEST,
  GET_ALL_PRODUCT_SUCCESS,
  GET_PRODUCT_FAIL,
  GET_PRODUCT_REQUEST,
  GET_PRODUCT_SUCCESS,
} from "../constants/productConstants";
import Axios from "axios";

const BASE_URL = `${process.env.REACT_APP_BLINDS_SERVER}/api/v1/products`;

export const productListByShopIdAction =
  (shopId) => async (dispatch, getState) => {
    dispatch({
      type: GET_ALL_PRODUCT_BY_SHOP_ID_REQUEST,
      payload: { shopId },
    });

    try {
      const { data } = await Axios.get(
        `${BASE_URL}/productListByShopId?shopId=${shopId}`
      );

      dispatch({
        type: GET_ALL_PRODUCT_BY_SHOP_ID_SUCCESS,
        payload: data,
      });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      dispatch({
        type: GET_ALL_PRODUCT_BY_SHOP_ID_FAIL,
        payload: message,
      });
    }
  };

export const allProductListAction = () => async (dispatch, getState) => {
  dispatch({
    type: GET_ALL_PRODUCT_REQUEST,
    payload: {},
  });

  try {
    const { data } = await Axios.get(`${BASE_URL}/all`);
    dispatch({
      type: GET_ALL_PRODUCT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({
      type: GET_ALL_PRODUCT_FAIL,
      payload: message,
    });
  }
};

export const getProductDetails = (productId) => async (dispatch, getState) => {
  dispatch({
    type: GET_PRODUCT_REQUEST,
    payload: productId,
  });

  try {
    const {
      userSignIn: { userInfo },
    } = getState();

    const { data } = await Axios.get(
      `${BASE_URL}/details/${productId}`,
      getAuthorization(userInfo.token)
    );
    dispatch({
      type: GET_PRODUCT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({
      type: GET_PRODUCT_FAIL,
      payload: message,
    });
  }
};
