import { Footer, Header } from "../../components/common";
import React, { ElementType } from "react";

interface IProps {
  layout: ElementType;
}

export const PublicRoute = (props: any) => {
  const { children } = props;

  return (
    <div className="flex flex-col min-h-screen w-full">
      <Header />
      <div className="flex-1 w-full bg-gray-100 px-8 py-4">{children}</div>
      <Footer />
    </div>
  );
};
