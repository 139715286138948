import { HiOutlineArrowRight } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import { FaFacebook } from "react-icons/fa";
import { BsInstagram, BsYoutube } from "react-icons/bs";
import { logo } from "../../../accets";

export function Footer() {
  const navigate = useNavigate();

  return (
    <footer className="bg-gray-900 text-white">
      {/* Desktop View */}
      <div className="hidden md:block px-4 lg:px-[300px] py-4 lg:py-10">
        <div className="flex justify-between items-center">
          <img src={logo} alt="Logo" className="h-[70px] w-[180px]" />
          <button
            className="flex items-center gap-2 px-6 py-3 text-lg lg:text-2xl bg-white text-gray-900 hover:bg-teal-400 hover:text-white transition duration-300 rounded"
            onClick={() => navigate("/contactUs")}
          >
            Contact Us <HiOutlineArrowRight />
          </button>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 lg:gap-20 pt-4 lg:pt-10">
          {/* Corporate Office */}
          <div>
            <h3 className="text-lg font-semibold">Corporate Office</h3>
            <p className="text-sm pt-3">Balajee Engineering Works,</p>
            <p className="text-sm">Tetaria more, EKawana</p>
            <p className="text-sm">Ara, Bhojpur, Bihar - 802301 INDIA</p>
          </div>

          {/* Links */}
          <div>
            <h3 className="text-lg font-semibold">Quick Links</h3>
            <p className="text-sm pt-3">About Us</p>
            <p className="text-sm pt-3">Our Businesses</p>
            <p className="text-sm pt-3">People</p>
          </div>

          {/* Contact */}
          <div>
            <h3 className="text-lg font-semibold">Contact Us</h3>
            <p className="text-sm pt-3">+919650255062, +919939397519</p>
            <p className="text-sm pt-1">vishalkumar70522@gmail.com</p>
            <div className="flex gap-4 pt-2">
              <FaFacebook size={20} />
              <BsInstagram size={20} />
              <BsYoutube size={25} />
            </div>
          </div>
        </div>
      </div>

      {/* Mobile View */}
      <div className="block md:hidden px-4 py-4 lg:py-10 text-center">
        <div className="flex justify-between items-center">
          <img src={logo} alt="Logo" className="h-[70px] w-[180px]" />
          <button
            className="flex items-center gap-2 px-6 py-3 text-lg lg:text-2xl bg-white text-gray-900 hover:bg-orange-500 hover:text-white transition duration-300 rounded"
            onClick={() => navigate("/contactUs")}
          >
            Contact Us <HiOutlineArrowRight />
          </button>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 pt-4 lg:pt-10">
          <div>
            <h3 className="text-lg font-semibold pt-3">Corporate Office</h3>
            <p className="text-sm pt-3">Balajee Engineering Works,</p>
            <p className="text-sm">Tetaria more, EKawana</p>
            <p className="text-sm">Ara, Bhojpur, Bihar - 802301 INDIA</p>
          </div>

          <div>
            <h3 className="text-lg font-semibold pt-3">Quick Links</h3>
            <p className="text-sm pt-3">About Us</p>
            <p className="text-sm pt-3">Our Businesses</p>
            <p className="text-sm pt-3">People</p>
          </div>

          <div>
            <h3 className="text-lg font-semibold pt-3">Contact Us</h3>
            <p className="text-sm pt-3">+919650255062, +919939397519</p>
            <p className="text-sm pt-3">vishalkumar70522@gmail.com</p>
            <div className="flex gap-4 justify-center pt-2">
              <FaFacebook size={20} />
              <BsInstagram size={20} />
              <BsYoutube size={25} />
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
