import { Route, Routes } from "react-router-dom";
import {
  ContactUs,
  Gallery,
  HomePage,
  ShopDetails,
  SingleProductType,
} from "../pages";
import { PublicRoute } from "../layout/PublicRoute";

export function Routers() {
  return (
    <>
      <Routes>
        <Route
          path="/"
          element={
            <PublicRoute>
              <HomePage />
            </PublicRoute>
          }
        />
        <Route
          path="/contactUs"
          element={
            <PublicRoute>
              <ContactUs />
            </PublicRoute>
          }
        />
        <Route
          path="/shop-details"
          element={
            <PublicRoute>
              <ShopDetails />
            </PublicRoute>
          }
        />
        <Route
          path="/gallery"
          element={
            <PublicRoute>
              <Gallery />
            </PublicRoute>
          }
        />
        <Route
          path="/product-details"
          element={
            <PublicRoute>
              <SingleProductType />
            </PublicRoute>
          }
        />
      </Routes>
    </>
  );
}
