import { useNavigate } from "react-router-dom";
import { GiHamburgerMenu } from "react-icons/gi";
import { logo } from "../../../accets";
import { useState } from "react";

export function Header() {
  const navigate = useNavigate();
  const [isMenuOpen, setMenuOpen] = useState(false);

  return (
    <header className="bg-gray-900 p-3">
      <div className="flex justify-between items-center">
        {/* Logo */}
        <div className="pl-4 lg:pl-20">
          <img
            src={logo}
            alt="Logo"
            className="h-[50px] w-[100px] cursor-pointer"
            onClick={() => navigate("/")}
          />
        </div>

        {/* Desktop Navigation */}
        <nav className="hidden md:flex gap-6">
          <button
            className="text-white text-lg hover:text-orange-500 transition"
            onClick={() => navigate("/")}
          >
            Home
          </button>
          <button
            className="text-white text-lg hover:text-orange-500 transition"
            onClick={() => navigate("/")}
          >
            About Us
          </button>
          <button
            className="text-white text-lg hover:text-orange-500 transition"
            onClick={() => navigate("/products")}
          >
            Products & Services
          </button>
          <button
            className="text-white text-lg hover:text-orange-500 transition"
            onClick={() => navigate("/contactUs")}
          >
            Contact Us
          </button>
          <button
            className="text-white text-lg hover:text-orange-500 transition"
            onClick={() => navigate("/gallery")}
          >
            Gallery
          </button>
        </nav>

        {/* Mobile Navigation */}
        <div className="md:hidden">
          <button onClick={() => setMenuOpen(!isMenuOpen)}>
            <GiHamburgerMenu size={25} className="text-white" />
          </button>
          {isMenuOpen && (
            <div className="absolute right-4 mt-2 w-48 bg-white shadow-lg rounded-md overflow-hidden">
              <button
                className="block w-full text-left px-4 py-2 hover:bg-gray-200"
                onClick={() => navigate("/")}
              >
                Home
              </button>
              <button
                className="block w-full text-left px-4 py-2 hover:bg-gray-200"
                onClick={() => navigate("/")}
              >
                About Us
              </button>
              <button
                className="block w-full text-left px-4 py-2 hover:bg-gray-200"
                onClick={() => navigate("/products")}
              >
                Products & Services
              </button>
              <button
                className="block w-full text-left px-4 py-2 hover:bg-gray-200"
                onClick={() => navigate("/contactUs")}
              >
                Contact Us
              </button>
              <button
                className="block w-full text-left px-4 py-2 hover:bg-gray-200"
                onClick={() => navigate("/gallery")}
              >
                Gallery
              </button>
            </div>
          )}
        </div>
      </div>
    </header>
  );
}
