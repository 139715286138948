import {
  GALLERY_CATEGORY_LIST_FAIL,
  GALLERY_CATEGORY_LIST_REQUEST,
  GALLERY_CATEGORY_LIST_SUCCESS,
  GET_ALL_GALLERY_FAIL,
  GET_ALL_GALLERY_REQUEST,
  GET_ALL_GALLERY_SUCCESS,
  GET_GALLERY_BY_SHOP_ID_FAIL,
  GET_GALLERY_BY_SHOP_ID_REQUEST,
  GET_GALLERY_BY_SHOP_ID_SUCCESS,
  GALLERY_CATEGORY_LIST_RESET,
  GET_ALL_GALLERY_RESET,
  GET_GALLERY_BY_SHOP_ID_RESET,
} from "../constants/galleryConstants";

export function getAllGalleryReducer(state = { customers: [] }, action) {
  switch (action.type) {
    case GET_ALL_GALLERY_REQUEST:
      return { loading: true };
    case GET_ALL_GALLERY_SUCCESS:
      return {
        loading: false,
        success: true,
        data: action.payload,
      };
    case GET_ALL_GALLERY_FAIL:
      return { loading: false, error: action.payload };
    case GET_ALL_GALLERY_RESET:
      return {};
    default:
      return state;
  }
}

export function getGalleryListByShopIdReducer(state = {}, action) {
  switch (action.type) {
    case GET_GALLERY_BY_SHOP_ID_REQUEST:
      return { loading: true };
    case GET_GALLERY_BY_SHOP_ID_SUCCESS:
      return {
        loading: false,
        success: true,
        data: action.payload,
      };
    case GET_GALLERY_BY_SHOP_ID_FAIL:
      return { loading: false, error: action.payload };
    case GET_GALLERY_BY_SHOP_ID_RESET:
      return {};
    default:
      return state;
  }
}

export function getGalleryCategoryReducer(state = {}, action) {
  switch (action.type) {
    case GALLERY_CATEGORY_LIST_REQUEST:
      return { loading: true };
    case GALLERY_CATEGORY_LIST_SUCCESS:
      return {
        loading: false,
        success: true,
        data: action.payload,
      };
    case GALLERY_CATEGORY_LIST_FAIL:
      return { loading: false, error: action.payload };
    case GALLERY_CATEGORY_LIST_RESET:
      return {};
    default:
      return state;
  }
}
