export const GET_ALL_GALLERY_REQUEST = "GET_ALL_GALLERY_REQUEST";
export const GET_ALL_GALLERY_SUCCESS = "GET_ALL_GALLERY_SUCCESS";
export const GET_ALL_GALLERY_FAIL = "GET_ALL_GALLERY_FAIL";
export const GET_ALL_GALLERY_RESET = "GET_ALL_GALLERY_RESET";

export const GET_GALLERY_BY_SHOP_ID_REQUEST = "GET_GALLERY_BY_SHOP_ID_REQUEST";
export const GET_GALLERY_BY_SHOP_ID_SUCCESS = "GET_GALLERY_BY_SHOP_ID_SUCCESS";
export const GET_GALLERY_BY_SHOP_ID_FAIL = "GET_GALLERY_BY_SHOP_ID_FAIL";
export const GET_GALLERY_BY_SHOP_ID_RESET = "GET_GALLERY_BY_SHOP_ID_RESET";

export const GALLERY_CATEGORY_LIST_REQUEST = "GALLERY_CATEGORY_LIST_REQUEST";
export const GALLERY_CATEGORY_LIST_SUCCESS = "GALLERY_CATEGORY_LIST_SUCCESS";
export const GALLERY_CATEGORY_LIST_FAIL = "GALLERY_CATEGORY_LIST_FAIL";
export const GALLERY_CATEGORY_LIST_RESET = "GALLERY_CATEGORY_LIST_RESET";
