import {
  
  GET_ALL_PRODUCT_BY_SHOP_ID_FAIL,
  GET_ALL_PRODUCT_BY_SHOP_ID_REQUEST,
  GET_ALL_PRODUCT_BY_SHOP_ID_RESET,
  GET_ALL_PRODUCT_BY_SHOP_ID_SUCCESS,
  GET_ALL_PRODUCT_FAIL,
  GET_ALL_PRODUCT_REQUEST,
  GET_ALL_PRODUCT_RESET,
  GET_ALL_PRODUCT_SUCCESS, 
  GET_PRODUCT_FAIL,
  GET_PRODUCT_REQUEST,
  GET_PRODUCT_RESET,
  GET_PRODUCT_SUCCESS,
} from "../constants/productConstants";

export function getProductListByShopIdReducer(state = { data: [] }, action) {
  switch (action.type) {
    case GET_ALL_PRODUCT_BY_SHOP_ID_REQUEST:
      return { loading: true };
    case GET_ALL_PRODUCT_BY_SHOP_ID_SUCCESS:
      return {
        loading: false,
        success: true,
        data: action.payload,
      };
    case GET_ALL_PRODUCT_BY_SHOP_ID_FAIL:
      return { loading: false, error: action.payload };
    case GET_ALL_PRODUCT_BY_SHOP_ID_RESET:
      return {};
    default:
      return state;
  }
}

export function getAllProductListReducer(state = { data: [] }, action) {
  switch (action.type) {
    case GET_ALL_PRODUCT_REQUEST:
      return { loading: true };
    case GET_ALL_PRODUCT_SUCCESS:
      return {
        loading: false,
        success: true,
        data: action.payload,
      };
    case GET_ALL_PRODUCT_FAIL:
      return { loading: false, error: action.payload };
    case GET_ALL_PRODUCT_RESET:
      return {};
    default:
      return state;
  }
}

export function getProductDetailsReducer(state = {}, action) {
  switch (action.type) {
    case GET_PRODUCT_REQUEST:
      return { loading: true };
    case GET_PRODUCT_SUCCESS:
      return {
        loading: false,
        success: true,
        data: action.payload,
      };
    case GET_PRODUCT_FAIL:
      return { loading: false, error: action.payload };
    case GET_PRODUCT_RESET:
      return {};
    default:
      return state;
  }
}
