import {
  SHOP_LIST_FAIL,
  SHOP_LIST_REQUEST,
  SHOP_LIST_RESET,
  SHOP_LIST_SUCCESS,
} from "../constants/shopConstants";

export function getShopListReducer(state = {}, action) {
  switch (action.type) {
    case SHOP_LIST_REQUEST:
      return { loading: true };
    case SHOP_LIST_SUCCESS:
      return {
        loading: false,
        success: true,
        data: action.payload,
      };
    case SHOP_LIST_FAIL:
      return { loading: false, error: action.payload };
    case SHOP_LIST_RESET:
      return {};
    default:
      return state;
  }
}
