import React from "react";
import { ProductRating } from "./ProductRating";

export const ProductCard = ({ product }: any) => {
  const defaultImage =
    "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTNNLEL-qmmLeFR1nxJuepFOgPYfnwHR56vcw&s";

  return (
    <div className="h-[330px] w-[250px] bg-white shadow-md rounded-lg p-2 transition-all transform hover:scale-105">
      <div className="h-[200px] w-full rounded-md bg-gray-100 flex items-center justify-center">
        <img
          src={product?.images?.length > 0 ? product.images[0] : defaultImage}
          alt={product?.description}
          className="h-full w-full object-contain rounded-md"
          onError={(e) => ((e.target as HTMLImageElement).src = defaultImage)}
        />
      </div>
      <div className="mt-1 space-y-0 px-1">
        <h1 className="text-[14px] font-bold truncate">
          {product?.description}
        </h1>
        <h1 className="text-[12px] text-gray-600">
          Available Quantity:{" "}
          <span className="text-black">
            {product?.quantity} {product?.priceType}
          </span>
        </h1>
        <h1 className="text-[12px] text-gray-600">
          Category: <span className="text-black">{product?.category}</span>
        </h1>
        <h1 className="text-[12px] text-gray-600">
          Price: <span className="text-black">₹{product?.sellingPrice}</span>
        </h1>
        <div className="flex items-center gap-1 text-[12px] text-gray-600">
          Rating: <ProductRating rating={product?.rating || 5} />
        </div>
      </div>
    </div>
  );
};
