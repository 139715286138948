import React from "react";

export const ProductRating = ({ rating, maxRating = 5 }: any) => {
  const fullStars = Math.floor(rating);
  const hasHalfStar = rating - fullStars >= 0.5;

  return (
    <div className="flex items-center gap-1">
      {Array.from({ length: maxRating }).map((_, index) => (
        <span key={index} className="text-yellow-400 text-xl">
          {index < fullStars
            ? "★"
            : index === fullStars && hasHalfStar
            ? "⭐"
            : "☆"}
        </span>
      ))}
      <span className="text-gray-500 text-sm ml-2">({rating.toFixed(1)})</span>
    </div>
  );
};
