import { useEffect, useState } from "react";
import { ProductCard, ShopCard, SearchInputField } from "../../components";
import { useLocation } from "react-router-dom";
import SimpleImageSlider from "react-simple-image-slider";
import { useDispatch } from "react-redux";
import { productListByShopIdAction } from "actions/productAction";
import { useSelector } from "react-redux";

export function ShopDetails() {
  const location = useLocation();
  const dispatch = useDispatch<any>();
  const shop = location.state;
  const [searchText, setSearchText] = useState<String>("");
  const lat = shop?.geoLocation?.lat;
  const lng = shop?.geoLocation?.lng;

  // Dynamically construct map URL
  const mapSrc =
    lat && lng
      ? `https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d701.8275189241946!2d${lng}!3d${lat}!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d1f63a408f755%3A0x7ae950f99dce8a40!2sParas%20Trade%20Centre!5e0!3m2!1sen!2sin!4v1677944554176!5m2!1sen!2sin`
      : "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d701.8275189241946!2d77.13020842924387!3d28.436007404176013!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d1f63a408f755%3A0x7ae950f99dce8a40!2sParas%20Trade%20Centre!5e0!3m2!1sen!2sin"; // Fallback map

  const productListByShopId = useSelector(
    (state: any) => state.productListByShopId
  );
  const { loading, data: productsByShopId } = productListByShopId;

  useEffect(() => {
    dispatch(productListByShopIdAction(shop?.shopId));
  }, [shop]);

  return (
    <div className="p-4">
      {/* Image Slider (Responsive) */}
      {shop?.images?.length && (
        <div>
          {/* Desktop View */}
          <div className="hidden md:block">
            <SimpleImageSlider
              width="100%"
              height="900px"
              images={shop?.images || []}
              showBullets
              showNavs
              autoPlay
            />
          </div>
          {/* Mobile View */}
          <div className="md:hidden">
            <SimpleImageSlider
              width="100%"
              height="250px"
              images={shop?.images || []}
              showBullets
              showNavs
              autoPlay
            />
          </div>
        </div>
      )}

      {/* Shop Card (Responsive) */}
      <div className="w-full mt-4">
        <ShopCard shop={shop} />
      </div>

      {/* Google Maps Embed */}
      <div className="w-full h-[300px] md:h-[466px] mt-4">
        <iframe
          src={mapSrc}
          className="w-full h-full rounded-[16px]"
          allowFullScreen={true}
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
          title="Google Map"
        ></iframe>
      </div>

      {/* Search Input */}
      <div className="grid grid-cols-1 md:grid-cols-5 gap-4 py-4">
        <div className="col-span-1 md:col-span-3">
          <SearchInputField
            value={searchText}
            onChange={setSearchText}
            placeholder="Search product by product name"
          />
        </div>
      </div>

      {/* Product Cards (Responsive Grid) */}
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4">
        {productsByShopId
          ?.filter((product: any) =>
            product.description
              .toLowerCase()
              ?.includes(searchText.toLowerCase())
          )
          .map((product: any) => (
            <ProductCard key={product.id} product={product} />
          ))}
      </div>
    </div>
  );
}
