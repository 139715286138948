import { useLocation } from "react-router-dom";
import { Image } from "antd";

export function SingleProductType() {
  const location = useLocation();
  const products = location.state.data;
  const productName = location.state.name;

  return (
    <div className="px-2 lg:px-20 py-2 lg:py-10 bg-green-100">
      <h2 className="text-2xl lg:text-3xl font-semibold text-center text-gray-900">
        {productName}
      </h2>

      <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-4 lg:gap-20 pt-4 lg:pt-10">
        {products?.map((data, index) => (
          <div
            key={index}
            className="bg-teal-700 p-1 lg:p-3 rounded-lg shadow-md"
          >
            <Image.PreviewGroup items={[data?.image || data]}>
              <Image className="w-full rounded-lg" src={data?.image || data} />
            </Image.PreviewGroup>
            <p className="text-xs lg:text-lg font-medium text-white py-1 text-center">
              {data?.description}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
}
