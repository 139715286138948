import React from "react";
import { Shop } from "../../interfaces/shopInterface";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "antd";

// Define prop type for shop
interface ShopCardProps {
  shop: Shop;
}

export const ShopCard: React.FC<ShopCardProps> = ({ shop }) => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/shop-details", {
      state: shop,
    });
  };
  return (
    <Tooltip title="Click to go shop details page">
      <div
        className="p-4 border border-gray-300 hover:shadow-2xl transition-shadow rounded-[16px]"
        onClick={handleClick}
      >
        <div className="flex items-center gap-2">
          <i className="fi fi-ss-store-alt text-[#F58634]"></i>
          <div className="flex gap-4">
            <p>{shop.shopName}</p>
            {/* <p className="text-[#900C3F]">{shop.shopType}</p> */}
          </div>
        </div>
        <div className="flex items-center gap-2">
          <i className="fi fi-sr-land-layer-location text-[#F58634]"></i>
          <p>
            {shop.address} {shop.city} {shop.state}
          </p>
        </div>
        <div className="flex items-center gap-2 pt-2">
          <i className="fi fi-br-user text-[#F58634]"></i>
          <p>{shop.ownerName}</p>
        </div>
        <div className="flex items-center gap-2 pt-2">
          <i className="i fi-sr-phone-call text-[#F58634]"></i>
          <p>{shop.shopMobileNo}</p>
        </div>
        <div className="flex items-center gap-2 pt-2">
          <i className="fi fi fi-rs-alarm-clock text-[#F58634]"></i>
          <p>{"Mon-Sat 8:00 am - 6:00 pm"}</p>
        </div>
      </div>
    </Tooltip>
  );
};

export default ShopCard;
