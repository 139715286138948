import { useEffect } from "react";
import { getShopList } from "../../actions/shopAction";
import { useDispatch, useSelector } from "react-redux";
import { ShopCard } from "../../components";
import { Shop } from "../../interfaces/shopInterface";

export function ContactUs() {
  const dispatch = useDispatch<any>();
  const {
    error,
    loading,
    data: shopList,
  } = useSelector((state: any) => state.shopList);

  useEffect(() => {
    if (!shopList) dispatch(getShopList());
  }, [dispatch, shopList]);

  return (
    <div>
      <div
        className="bg-cover bg-center h-[100px] lg:h-[400px] w-full rounded-md"
        style={{
          backgroundImage:
            "url(https://ipfs.io/ipfs/bafkreicldnx5kopdisqu64azcqrjzyffro3xzrgx5ra547dvzhxkpzf7ha)",
        }}
      >
        <h1 className="text-[#F58634] text-center pt-20 lg:pt-[200px] text-4xl lg:text-6xl font-semibold">
          Contact Us
        </h1>
      </div>
      <div className="p-2 lg:p-10">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-1 lg:gap-10">
          {shopList?.map((shop: Shop, index: number) => (
            <ShopCard shop={shop} key={index} />
          ))}
        </div>
      </div>
    </div>
  );
}
