export const GET_ALL_PRODUCT_REQUEST = "GET_ALL_PRODUCT_REQUEST";
export const GET_ALL_PRODUCT_SUCCESS = "GET_ALL_PRODUCT_SUCCESS";
export const GET_ALL_PRODUCT_FAIL = "GET_ALL_PRODUCT_FAIL";
export const GET_ALL_PRODUCT_RESET = "GET_ALL_PRODUCT_RESET";

export const GET_ALL_PRODUCT_BY_SHOP_ID_REQUEST = "GET_ALL_PRODUCT_BY_SHOP_ID_REQUEST";
export const GET_ALL_PRODUCT_BY_SHOP_ID_SUCCESS = "GET_ALL_PRODUCT_BY_SHOP_ID_SUCCESS";
export const GET_ALL_PRODUCT_BY_SHOP_ID_FAIL = "GET_ALL_PRODUCT_BY_SHOP_ID_FAIL";
export const GET_ALL_PRODUCT_BY_SHOP_ID_RESET = "GET_ALL_PRODUCT_BY_SHOP_ID_RESET";

export const GET_PRODUCT_REQUEST = "GET_PRODUCT_REQUEST";
export const GET_PRODUCT_SUCCESS = "GET_PRODUCT_SUCCESS";
export const GET_PRODUCT_FAIL = "GET_PRODUCT_FAIL";
export const GET_PRODUCT_RESET = "GET_PRODUCT_RESET";
